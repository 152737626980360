<template>
    <dialog ref="deleteDialog" class=" q-display-flex-column ">
        <div class="q-display-flex-row q-align-stretch">
            <div class=" q-display-flex-column q-center q-flex-center-items" title="close">
                <i style="color:var(--q_orange);font-size: 1.3em;" class="ri-alert-line"></i>
            </div>
            <h3 style="margin: 0px;margin-left:16px;width: 100%;">{{ title }}</h3>

        </div>
        <div class="q-display-flex-row q-align-stretch" style="margin-bottom: 24px;margin-top: 16px;">
            <p>{{ description }}</p>
        </div>
        <div class="q-display-flex-row q-align-stretch">
            <div class=" q-layout-container q-progress-overlay-container content-container">
                <div class="q-layout-row   child-content-container  group-title-container">
                    <div class="content-container q-col-6 q-display-flex-column q-text-container">
                        <button data-can-be-disabled @click="acceptButtonClicked"
                            style="background-color: var(--gw_primary_color);margin-right: 4px;"
                            class="delete-button q-button q-display-flex-row q-center q-flex-center-items ">

                            <p style="color: white;margin: 0;">{{ acceptButtonText }}</p>
                        </button>
                    </div>
                    <div class="content-container q-col-6 q-display-flex-column q-text-container">
                        <button data-can-be-disabled @click="close"
                            style="border: 1px solid rgba(0,0,0, .12); margin-left: 4px;"
                            class="delete-button q-button q-display-flex-row q-center q-flex-center-items ">
                            <p>{{ rejectButtonText }}</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </dialog>
</template>

<script>
export default {
    name: "DialogComponent",
    data() {
        return {
            title: "Title",
            description: "Description",
            acceptButtonText: "OK",
            rejectButtonText: "Cancel"
        }
    },
    methods: {
        show: function (event, data) {
            this.title = data.title
            this.description = data.description
            this.$refs.deleteDialog.showModal()
        },
        acceptButtonClicked: function () {
            console.log("is accepted ")
            this.$emit('accepted', '')
            this.$refs.deleteDialog.close()
        }
        ,
        close: function () {
            this.$emit('rejected', '')
            this.$refs.deleteDialog.close()
        }
    }
};
</script>
<style scoped>
dialog {
    /* top: 50%;
    left: 50%; */
    /* -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%); */
    position: absolute;

}

.content-container,
.child-content-container {
    padding: 0 !important;
}

.q-button:hover {
    box-shadow: none;
    opacity: .9;
    cursor: pointer;

}

.q-button {
    border-radius: 12px !important;
}

.q-button p {
    margin: 0px;
}
</style>
