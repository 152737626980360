<template>

  <router-view />

</template>

<script>

import axios from "axios";
axios.defaults.withCredentials = true;
export default {
  name: "App",
  components: {},
  data: function () {
    return {
      loggedIn: false,
      ready: false,
    };
  },

  mounted: function () {

    this.$store.dispatch("user/reloadUser", {
      url: `${this.$store.getters["urls/baseUrl"]}user/checkSession/`,
      fetch: `${this.$store.getters["urls/baseUrl"]}/user/me`,
    });


  },

  updated() {
    console.log("updated in main ")
    console.log(this.$route)
  },
  methods: {
    // refresh: async function () {

    //   var url2 = this.$store.getters["urls/baseUrl"] + "/oauth/redirect";
    //   console.log("is-sdf -asdf =sf= as=f as=f =sdfs   " + this.$store.getters["urls/baseUrl"] + url2)
    //   try {
    //     var result = await axios.get(url2);
    //     console.log("msdf asdf asf asdf " + JSON.stringify(result))
    //     this.$router.push("/verify")
    //   } catch (e) {
    //     console.log("mydf asf asf " + e)
    //   }
    // },

  }
};
</script>

<style></style>
